import React from 'react';
import Cherry from '../assets/images/cherry.png';
import './styles/background.css';

// @ts-ignore
const Subcomponent = ({ hasTopWall, hasBottomWall, hasLeftWall, hasRightWall, hasDot, hasPowerup, hasCherry }) => {
    const subcomponentClasses = `subcomponent ${(hasDot || hasPowerup || hasCherry) ? 'with-item' : ''} ${
        hasTopWall ? 'has-top-wall' : ''
    } ${hasBottomWall ? 'has-bottom-wall' : ''} ${hasLeftWall ? 'has-left-wall' : ''} ${
        hasRightWall ? 'has-right-wall' : ''
    }`;

    return (
        <div className={subcomponentClasses}>
            {hasDot && <div className="dot"/>}
            {hasPowerup && <div className="powerup"/>}
            {hasCherry && <img className="cherry" src={Cherry} alt="cherry"/>}
        </div>
    );
};

const Background = () => {
    const grid = [
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: true, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: true },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: true, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: true },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: true },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: true, hasBottomWall: true, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: true, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: true, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: false, hasPowerup: true, hasCherry: false },
        ],
        [
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: true, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: false, hasCherry: true },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: false, hasPowerup: true, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: false, hasDot: true, hasPowerup: false, hasCherry: false },
            { hasTopWall: false, hasBottomWall: false, hasLeftWall: false, hasRightWall: true, hasDot: true, hasPowerup: false, hasCherry: false },
        ],
    ];

    return (
        <div className="pacman-background">
            {grid.map((row, rowIndex) => (
                <div key={rowIndex} className="row">
                    {row.map((subcomponentProps, columnIndex) => (
                        <Subcomponent
                            key={columnIndex}
                            {...subcomponentProps}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Background;